@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PolySans";
  src: url("./assets/font/PolySansTrial-Neutral.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/font/Inter-Regular.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
.active {
  color: #fff;
  background-color: #0d0c22;
  border-radius: 0.5rem;
  width: 100%;
  padding: 12px;
}
.parent-scroll::-webkit-scrollbar {
  display: none;
  height: 1px;
  width: 1px;
}

.parent-scroll::-webkit-scrollbar-thumb {
  display: none;
}
